



$theme-colors: (
  "light": #7DCEA0,
  "dark": #282c34
);

@import "../node_modules/bootstrap/scss/bootstrap";

html,
body,
#root,
:root {
  height:100%;
}

.App {
  // background-color: #282c34;
  // color: white;
  background-color: white;
  color: black;
  align-items: center;
  justify-content: center;
  height:100%;
  width:100%;
  overflow-x:hidden;
  overflow-y:hidden;
  box-sizing: border-box;
}

.App-header {
  width: 100%;
  //display: flex;
  text-align: center;
 // flex-direction: column;
  font-size: calc(10px + 2vmin);
  padding: 25px;
  margin-right: auto;
  margin-left: auto;
  color: #7DCEA0;
  border-bottom: 3px solid #7DCEA0;
  padding:0px;
}

.App-main {
  width: 100%;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.Page {
  overflow-y: visible;
  flex-flow: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-right: auto;// -0.5rem;
  margin-left: auto;// -0.5rem;
  margin-top: 0rem;// -0.5rem;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.row{
  width:100%;
}

.App-link {
  color: #61dafb;
}

.FW {
  width:100%;
}




.loading-background-animation {
  animation: loading-background-pulse 2s infinite;
}

@keyframes loading-background-pulse {
  0%, 100% {
    background-color: #D3D3D3;
  }
  50% {
    background-color: #7DCEA0;
  }
}


.clickable-light {
  transition: 0.6s;
  cursor: pointer;
}

.clickable-light:hover {
  background-color: #7DCEA0 !important;
}
