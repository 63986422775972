

@import "../node_modules/animatewithsass/animate.scss";
// see details in https://github.com/geoffgraham/animate.scss

//@import "animate.scss";


.fade-enter {
  opacity: 1;
  z-index: 1;
}

.fade-exit {
  opacity: 1;
  z-index: 1;
}

.fade-exit-done {
  opacity: 1;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  @include slideInRight(
    $duration: 0.5s,
    $count: 1,
    $delay: .0s,
    $function: ease,
    $fill: both
  );
  opacity: 1;
}

.fade-exit.fade-exit-active {
  @include slideOutLeft(
    $duration: 0.5s,
    $count: 1,
    $delay: .0s,
    $function: ease,
    $fill: both
  );
  opacity: 1;
}




.anim-pulse {
  @include pulse(
    $duration: 4s,
    $count: infinite,
    $delay: .0s,
    $function: ease,
    $fill: both
  );
  opacity: 1;
}
